var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "upsell-selection-card" },
    [
      _c(
        "v-card-title",
        { staticClass: "pa-0" },
        [
          _c(
            "v-toolbar",
            [
              _c("v-toolbar-title", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "ma-2 white--text",
                  attrs: { color: "blue-grey" },
                  on: { click: _vm.onCancel },
                },
                [
                  _vm._v(" Cancel "),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v(" mdi-close"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ma-2 white--text",
                  attrs: { color: "#93BD20" },
                  on: { click: _vm.onSave },
                },
                [
                  _vm._v(" Save "),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v(" mdi-check"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [{ name: "bar", rawName: "v-bar" }],
          staticClass: "scroll-container",
        },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c("v-data-table", {
                ref: "sortableList",
                staticClass: "el2",
                attrs: {
                  items: _vm.items,
                  headers: _vm.headers,
                  "disable-pagination": true,
                  "hide-default-footer": "",
                  "hide-default-header": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ item, isSelected }) {
                      return [
                        _c(
                          "tr",
                          {
                            staticClass: "upsell-selection-row",
                            attrs: { active: isSelected },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.toggleEither(item)
                              },
                            },
                          },
                          [
                            item.group
                              ? _c(
                                  "td",
                                  [
                                    _c("v-row", { staticClass: "pt-1" }, [
                                      _c("div", { staticClass: "group-icon" }),
                                      _c(
                                        "div",
                                        { staticClass: "group-title" },
                                        [_vm._v(_vm._s(item.title))]
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _c("td", [
                                  _c("div", { staticClass: "item-title" }, [
                                    _vm._v(_vm._s(item.title)),
                                  ]),
                                ]),
                            item.group == true
                              ? _c(
                                  "td",
                                  [
                                    _c("v-checkbox", {
                                      staticClass: "mt-0",
                                      attrs: {
                                        color: "rgba(98, 197, 241, 1)",
                                        "input-value": isSelected,
                                        "hide-details": "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.toggleGroup(item)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "td",
                                  [
                                    _c("v-checkbox", {
                                      staticClass: "mt-0",
                                      attrs: {
                                        color: "rgba(98, 197, 241, 1)",
                                        "input-value": isSelected,
                                        "hide-details": "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.toggleItem(item)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.selectedItems,
                  callback: function ($$v) {
                    _vm.selectedItems = $$v
                  },
                  expression: "selectedItems",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }