<template>
  <v-card class="upsell-selection-card">
    <v-card-title class="pa-0">
      <v-toolbar>
        <v-toolbar-title> {{ title }} </v-toolbar-title>
        <v-spacer />
        <v-btn color="blue-grey" class="ma-2 white--text" @click="onCancel">
          Cancel
          <v-icon right dark> mdi-close</v-icon>
        </v-btn>
        <v-btn color="#93BD20" class="ma-2 white--text" @click="onSave">
          Save
          <v-icon right dark> mdi-check</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <div v-bar class="scroll-container">
      <v-card-text class="pa-0">
        <v-data-table
          ref="sortableList"
          class="el2"
          v-model="selectedItems"
          :items="items"
          :headers="headers"
          :disable-pagination="true"
          hide-default-footer
          hide-default-header
        >
          <template v-slot:item="{ item, isSelected }">
            <tr
              class="upsell-selection-row"
              :active="isSelected"
              @click.stop="toggleEither(item)"
            >
              <td v-if="item.group">
                <v-row class="pt-1">
                  <div class="group-icon"></div>
                  <div class="group-title">{{ item.title }}</div>
                </v-row>
              </td>
              <td v-else>
                <div class="item-title">{{ item.title }}</div>
              </td>
              <td v-if="item.group == true">
                <v-checkbox
                  class="mt-0"
                  color="rgba(98, 197, 241, 1)"
                  :input-value="isSelected"
                  @click.stop="toggleGroup(item)"
                  hide-details
                ></v-checkbox>
              </td>
              <td v-else>
                <v-checkbox
                  class="mt-0"
                  color="rgba(98, 197, 241, 1)"
                  :input-value="isSelected"
                  @click.stop="toggleItem(item)"
                  hide-details
                ></v-checkbox>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ContextTitle from "components/context_title.vue";
import clone from "clone";

export default {
    data() {
        return {
            selectedItems: this.preSelectedItems,
            headers: [
                {
                    sortable: false,
                    text: "",
                    value: "name",
                },
                {
                    sortable: false,
                    text: "",
                    value: "state",
                },
            ],
        };
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        preSelectedItems: {
            type: Array,
            required: true,
        },
        includeGroups: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ContextTitle,
    },
    computed: {
        items() {
            if (
                this.productCategories == undefined ||
        this.productCategories.length == 0 ||
        this.selectedItems == undefined
            )
                return [];

            var items = [];

            let selectedItemSet = new Set(this.selectedItems.map((el) => el.id));

            if (this.includeGroups) {
                items = clone(this.groupItems);
            }

            for (let category of this.productCategories) {
                if (
                    category.inventoryTitleDescription == "SHOW ALL" ||
          !category.children.length
                )
                    continue;

                var allSelected = true;
                var categoryItem = {
                    id: category.inventoryTitleID,
                    group: true,
                    title: category.inventoryTitleDescription,
                    parentID: -1,
                };
                items.push(categoryItem);

                for (let item of category.children) {
                    if (!selectedItemSet.has(item.id)) allSelected = false;
                    items.push({
                        id: item.id,
                        group: false,
                        title: item.inventoryItemName,
                        parentID: category.inventoryTitleID,
                    });
                }
                if (
                    allSelected &&
          !this.selectedItems.findIndex((el) => el.id == categoryItem.id)
                )
                    this.selectedItems.push(categoryItem);
            }

            return items;
        },
        groupItems() {
            var groupItems = [];
            let selectedItemSet = new Set(this.selectedItems.map((el) => el.id));

            for (let group of this.upsellGroupsForStore) {
                if (!group.Enabled) continue;
                groupItems.push({
                    id: `${group.GroupID}`,
                    group: true,
                    title: group.GroupName,
                    parentID: -1,
                });

                for (let item of group.Items) {
                    groupItems.push({
                        id: `${item.InventoryItemID}`,
                        group: false,
                        title: item.InventoryItemName,
                        parentID: group.GroupID,
                    });
                }
            }

            return groupItems;
        },
        ...mapGetters(["productCategories"]),
        ...mapGetters("UpsellStore", ["upsellGroupsForStore"]),
    },
    methods: {
        onCancel() {
            this.$emit("close");
        },
        onSave() {
            this.$emit("save", this.selectedItems);
        },
        toggleEither(item) {
            if (item.group) {
                this.toggleGroup(item);
            } else {
                this.toggleItem(item);
            }
        },
        toggleItem(item) {
            let itemIndex = this.selectedItems.findIndex((el) => el.id == item.id);

            let selected = itemIndex != -1;
            if (!selected) {
                this.selectedItems.push(item);
                let selectedItemSet = new Set(this.selectedItems.map((el) => el.id));
                let siblings = this.items.filter((el) => el.parentID == item.parentID);
                let allSelected = siblings.reduce((result, el) => {
                    return result && selectedItemSet.has(el.id);
                }, true);

                if (allSelected) {
                    let parent = this.items.find((el) => el.id == item.parentID);
                    this.selectedItems.push(parent);
                }
            } else {
                this.selectedItems.splice(itemIndex, 1);
                let categoryIndex = this.selectedItems.findIndex(
                    (el) => el.id == item.parentID
                );
                if (categoryIndex != -1) {
                    this.selectedItems.splice(categoryIndex, 1);
                }
            }
        },
        toggleGroup(groupItem) {
            let upsellGroupIndex = this.upsellGroupsForStore.findIndex(
                (el) => el.GroupID == groupItem.id
            );
            if (upsellGroupIndex == -1) {
                this.toggleCategory(groupItem);
                return;
            }

            let groupIndex = this.selectedItems.findIndex(
                (el) => el.id == groupItem.id
            );
            let selected = groupIndex != -1;

            if (!selected) {
                this.selectedItems.push(groupItem);
            } else {
                this.selectedItems.splice(groupIndex, 1);
            }

            let group = clone(this.upsellGroupsForStore[upsellGroupIndex]);

            for (let item of group.Items) {
                let index = this.selectedItems.findIndex(
                    (el) => el.id == `${item.InventoryItemID}`
                );
                if (selected && index != -1) {
                    this.selectedItems.splice(index, 1);
                } else if (!selected && index == -1) {
                    this.selectedItems.push({
                        id: `${item.InventoryItemID}`,
                        group: false,
                        title: item.InventoryItemName,
                        parentID: `${group.GroupID}`,
                    });
                }
            }
        },
        toggleCategory(categoryItem) {
            let categoryIndex = this.selectedItems.findIndex(
                (el) => el.id == categoryItem.id
            );
            let selected = categoryIndex != -1;

            if (!selected) {
                this.selectedItems.push(categoryItem);
            } else {
                this.selectedItems.splice(categoryIndex, 1);
            }

            let category = this.productCategories.find(
                (el) => el.inventoryTitleID == categoryItem.id
            );
            if (category === undefined) return;

            for (let item of category.children) {
                let index = this.selectedItems.findIndex((el) => el.id == item.id);
                if (selected && index != -1) {
                    this.selectedItems.splice(index, 1);
                } else if (!selected && index == -1) {
                    this.selectedItems.push({
                        id: item.id,
                        group: false,
                        title: item.inventoryItemName,
                    });
                }
            }
        },
    },
    created() {
        this.selectedItems = clone(this.preSelectedItems)
        let selectedItemSet = new Set(this.preSelectedItems.map((el) => el.id));

        var copy = clone(this.preSelectedItems);

        for (let category of this.productCategories) {
            if (
                category.inventoryTitleDescription == "SHOW ALL" ||
        !category.children.length
            )
                continue;

            var categoryItem = {
                id: category.inventoryTitleID,
                group: true,
                title: category.inventoryTitleDescription,
                parentID: -1,
            };
            let allSelected = category.children.reduce((result, el) => {
                return result && selectedItemSet.has(el.id);
            }, true);
            if (allSelected) this.selectedItems.push(categoryItem);
        }

        if (!this.includeGroups) {
            return;
        }

        for (let group of this.upsellGroupsForStore) {
            if (!group.Enabled) continue;
            let groupItem = {
                id: `${group.GroupID}`,
                group: true,
                title: group.GroupName,
                parentID: -1,
            };

            let allSelected = group.Items.reduce((result, el) => {
                return result && selectedItemSet.has(`${el.InventoryItemID}`);
            }, true);
            if (allSelected) this.selectedItems.push(groupItem);
        }
    },
    // watch: {
    //   preSelectedItems(newValue, oldValue) {
    //     let selectedItemSet = new Set(newValue.map(el => el.id));

    //     var copy = clone(newValue);

    //     for (let category of this.productCategories) {
    //       if (category.inventoryTitleDescription == "SHOW ALL") continue;

    //       var categoryItem = {
    //         id: category.inventoryTitleID,
    //         group: true,
    //         title: category.inventoryTitleDescription,
    //         parentID: -1
    //       };
    //       let allSelected = category.children.reduce((result, el) => {
    //         return result && selectedItemSet.has(el.id);
    //       }, true);
    //       if (allSelected) copy.push(categoryItem);
    //     }
    //     if (!this.includeGroups) {
    //       this.selectedItems = copy;
    //       return;
    //     }
    //   }
    // }
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";
@import "~css/vuebar.css";

.upsell-selection-card {
  .v-data-table {
    tbody {
      tr:hover:not(.v-data-table__expand-row) {
        background: rgba(98, 197, 241, 0.2);
      }
      tr[active] {
        background: rgba(98, 197, 241, 0.4);
      }
      tr {
        td {
          color: #585858 !important;
          vertical-align: middle;
          height: 41px;
          font-size: 14px;
          padding-left: 30px;
          width: 250px;
        }
      }
    }
  }
  .scroll-container {
    height: 60vh;
    margin-bottom: 5px;
  }
  .upsell-selection-row {
    color: #585858 !important;
    font-size: 14px;
    vertical-align: middle;
  }

  .group-title {
    padding-left: 20px;
  }
  .item-title {
    padding-left: 50px;
  }
  .group-icon {
    @include background-image("", "folder_open_logo.svg", "../../img/");
    background-size: contain;
    width: spacing(sm);
    height: spacing(sm);
  }
}
</style>
